<template>
    <BaseLayout>
        <div class="app-page">
            <!-- Top Navigation Bar -->
            <nav class="navbar">
                <div 
                v-for="section in sections" 
                :key="section.title" 
                class="nav-item" 
                @click="$redirectToPage(section.mainPage)"
                >
                {{ section.title }}
                </div>
            </nav>

            <!-- Main Content Area -->
            <div class="content">
            <slot></slot>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import { mapState } from 'vuex';
import BaseLayout from '@/components/layouts/BaseLayout.vue';
export default {
    components: {
        BaseLayout,
    },
    computed: {
        ...mapState(['selectedApp']),
        sections() {
            const appsSections = {
                'financieras': [
                    { title: 'OnBoarding', mainPage: 'OnBoardingDashboard', },
                    { title: 'Generador de Documentos', mainPage: 'DocGeneratorDashboard', },
                    { title: 'Importación de Datos', mainPage: 'DataImporterDashboard', },
                ],
                'admin': [
                    //{ title: 'Acceso de Usuarios', mainPage: 'user-access', },
                    { title: 'Generador de Documentos', mainPage: 'DocGeneratorDashboard', },
                ],
            };
            return appsSections[this.selectedApp] || [];
        }
    },
};
</script>

<style scoped>
    .app-page {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    }

    /* Professional Navigation Bar */
    .navbar {
    display: flex;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 15px;
    border-bottom: 2px solid #e9ecef;
    }

    .nav-item {
    margin: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s ease;
    }

    .nav-item:hover {
    color: #0056b3;
    }

    /* Content Area */
    .content {
    background-color: #ffffff;
    padding: 60px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    }

    .content h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
    }

    .content p {
    font-size: 18px;
    color: #666;
    }
</style>
