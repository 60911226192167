<template>
    <AppBaseLayout>
        <div class="configuration-page" v-if="template && configuration">
            <h2>Editando Configuración de Plantilla {{ template.name }}</h2>
            <div class="sections">
                <div class="pdf-viewer">
                    <h3>Ejemplo de Documento Generado</h3>
                    <iframe v-if="template" :src="template.pdf_file" width="100%" height="500px" frameborder="0"></iframe>
                </div>

                <div class="form-fields">
                    <!-- Main Fields Configuration -->
                    <div>
                        <h3>Configuración de Campos Principales</h3>
                        <div class="fields-list">
                            <div v-for="(value, key) in fieldsConfig" :key="key" class="field-item">
                                <label class="field-label"><b>{{ value.label ? value.label : key }}</b>: <span class="field-value">{{ value.type }}</span></label>
                                <TextField v-model="fieldsConfig[key].label" title="Nombre del campo" v-if="isAdmin"/>
                                <div class="row">
                                    <TextField v-model="fieldsConfig[key].default" title="Valor por default" />
                                    <Checkbox v-model="fieldsConfig[key].required" title="Requerido" />
                                </div>
                                <TextField v-model="fieldsConfig[key].example" title="Valor de ejemplo" style="margin-bottom: 5px;"/>
                                <LinkedFieldSelector v-if="isAdmin && DBObjects(filter='individual').length > 0" :objects="DBObjects(filter='individual')"
                                :initialObject="fieldsConfig[key].objLink ? fieldsConfig[key].objLink.object : null"
                                :initialField="fieldsConfig[key].objLink ? fieldsConfig[key].objLink.field : null"
                                @fieldSelected="(selectedValue) => objFieldSelected(key, selectedValue)"/>
                            </div>
                        </div>
                    </div>

                    <!-- Optional Sections Configuration -->
                    <div v-if="Object.keys(optionalSectionsConfig).length > 0">
                        <h3>Secciones Opcionales</h3>
                        <div class="optional-list">
                            <div v-for="(values, key) in optionalSectionsConfig" :key="key" class="field-item">
                                <label class="field-label">{{ values.label }}</label>
                                <div class="row">
                                    <TextField v-model="optionalSectionsConfig[key].label" title="Nombre de la sección" v-if="isAdmin" style="margin-bottom: 5px;"/>
                                    <Checkbox v-model="optionalSectionsConfig[key].value" title="Incluir" />
                                </div>
                                <LinkedFieldSelector v-if="isAdmin && DBObjects(filter='individual').length > 0" :objects="DBObjects(filter='individual')"
                                :initialObject="optionalSectionsConfig[key].objLink ? optionalSectionsConfig[key].objLink.object : null"
                                :initialField="optionalSectionsConfig[key].objLink ? optionalSectionsConfig[key].objLink.field : null"
                                @fieldSelected="(selectedValue) => optObjFieldSelected(key, selectedValue)"/>
                            </div>
                        </div>
                    </div>

                    <!-- Repetitive Fields Configuration -->
                    <div v-if="Object.keys(repetitiveFieldsConfig).length > 0">
                        <h3>Configuración de Campos Repetitivos</h3>
                        <div class="repetitive-fields">
                            <div v-for="(sect_data, section) in repetitiveFieldsConfig" :key="section" class="repetitive-field-item">
                                <h4 class="field-label">{{ sect_data.label }}</h4>
                                <TextField v-model="repetitiveFieldsConfig[section].label" title="Nombre de la lista" v-if="isAdmin"/>
                                <div class="repDBLink" v-if="isAdmin && DBObjects(filter='multiple').length > 0">
                                    <LinkedFieldDropdown label="Conexión con Objeto en DB" :options="DBObjects(filter='multiple')"
                                    :initialValue="repetitiveFieldsConfig[section].linkedObject"
                                    @selectionChanged="(selectedValue) => repObjSelected(section, selectedValue)"/>
                                </div>
                                <div v-for="(field, key) in sect_data.fields" :key="key" class="field-item">
                                    <label class="field-label">{{ field.label }}: <span class="field-value">{{ field.type }}</span></label>
                                    <TextField v-model="repetitiveFieldsConfig[section]['fields'][key].label" title="Nombre del campo" v-if="isAdmin"/>
                                    <div class="row">
                                        <TextField v-model="repetitiveFieldsConfig[section]['fields'][key].default" title="Valor por default"/>
                                        <Checkbox v-model="repetitiveFieldsConfig[section]['fields'][key].required" title="Requerido"/>
                                    </div>
                                    <TextField v-model="repetitiveFieldsConfig[section]['fields'][key].example" title="Valor de ejemplo"/>
                                    <LinkedFieldDropdown :label="`Campo ligado a ${linkedDBModels[repetitiveFieldsConfig[section].linkedObject].name}`" :options="objectFields(repetitiveFieldsConfig[section].linkedObject)" v-if="isAdmin && this.repetitiveFieldsConfig[section].linkedObject"
                                    :initialValue="repetitiveFieldsConfig[section]['fields'][key].linkedField"
                                    @selectionChanged="(selectedValue) => repFieldSelected(section, key, selectedValue)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                    </div>

                    <!-- Buttons for Saving or Editing Configuration -->
                    <div class="config-buttons">
                        <button v-if="configuration" @click="resetConfiguration" class="reset-btn">Formatear Configuración</button>
                        <button v-if="configuration" @click="saveConfiguration" class="generate-btn">Guardar Configuración</button>
                    </div>

                </div>

                <div class="db-relations" v-if="isAdmin">
                    <div>
                        <h3>Relaciones con Bases de Datos</h3>
                        <div class="db-record-fields">
                            <h4>Objetos Relacionados</h4>
                            <div v-for="(linkedDBModel, key) in linkedDBModels" :key="key" style="margin-bottom: 20px;">
                                <TextField v-model="linkedDBModel.name" :title="`Nombre de ${key}`" :required="true"/>
                                <div class="row">
                                    <LinkedFieldDropdown label="Aplicación" :options="DBApps"
                                    :initialValue="linkedDBModel.app"
                                    @selectionChanged="(selectedValue) => appSelected(key, selectedValue)" />
                                    <LinkedFieldDropdown label="Modelo (Tabla)" :options="DBModelsOptions(this.linkedDBModels[key].app)" v-if="linkedDBModel.app"
                                    :initialValue="linkedDBModel.model"
                                    @selectionChanged="(selectedValue) => modelSelected(key, selectedValue)" />
                                    <Checkbox v-model="linkedDBModel.multiple" title="Multiple" />
                                </div>
                            </div>
                            <button @click="addLinkedDBObject()" class="add-item-btn">Agregar Objeto</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import { mapGetters } from 'vuex';
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';
import TextField from '@/components/TextField.vue';
import Checkbox from '@/components/Checkbox.vue';
import LinkedFieldSelector from '@/components/LinkedFieldSelector.vue';
import LinkedFieldDropdown from '@/components/LinkedFieldDropdown.vue';

export default {
    components: {
        AppBaseLayout,
        TextField,
        Checkbox,
        LinkedFieldSelector,
        LinkedFieldDropdown
    },
    data() {
        return {
            template: null,
            configuration: null,

            fieldsConfig: {},
            optionalSectionsConfig: {},
            repetitiveFieldsConfig: {},

            linkedDBModels: {
                'object_1': {
                    name: null,
                    app: null,
                    model: null,
                    multiple: false,
                    ready: false
                }
            },

            DBModels: {},

            isCustom: true,

            errorMessage: ''
        };
    },
    computed: {
        ...mapGetters(['isAdmin']),
        configurationId() {
            return this.$route.params.id;
        },
        DBApps() {
            return Object.keys(this.DBModels).map(app => ({
                value: app,
                display: app
            }));
        },
        areModelsReady() {
            return Object.values(this.linkedDBModels).some(model => model.ready);
        }
    },
    async created() {
        try {
            const customTemplateConfigResponse = await api.get(`doc-generator/templates/configurations/custom/${this.configurationId}/`);
            this.configuration = customTemplateConfigResponse.data;
            this.isCustom = true;
        } catch (error) {
            if (!this.isAdmin) {
                this.$redirectToPage('DocGeneratorDashboard');
            }
            const templateConfigResponse = await api.get(`doc-generator/templates/configurations/${this.configurationId}/`);
            this.configuration = templateConfigResponse.data;
            this.isCustom = false;
        }

        const templateResponse = await api.get(`doc-generator/templates/${this.configuration.template}/`);
        this.template = templateResponse.data;

        if (this.isAdmin) {
            const DBModelsResponse = await api.get('doc-generator/db-connections/models/');
            this.DBModels = DBModelsResponse.data;
        }

        this.setConfigFields();
    },
    methods: {
        async resetConfiguration() {
            if (confirm('¿Estás seguro de que deseas formatear la configuración?')) {
                const basePath = `doc-generator/templates/configurations/`;
                const path = this.isCustom 
                    ? `${basePath}custom/${this.configuration.id}/reset/` 
                    : `${basePath}${this.configuration.id}/reset/`;

                const templateConfigResponse = await api.post(path);
                this.configuration = templateConfigResponse.data;
                this.setConfigFields();
            }
        },
        async saveConfiguration() {
            if (confirm('¿Estás seguro de que deseas actualizar la configuración?')) {
                var fields = this.fieldsConfig;
                const config = {
                    fields: fields,
                    optional_sections: this.optionalSectionsConfig,
                    repetitive_data: this.repetitiveFieldsConfig,
                    linked_objects: this.linkedDBModels,
                };

                const basePath = `doc-generator/templates/configurations/`;
                const path = this.isCustom 
                    ? `${basePath}custom/${this.configuration.id}/` 
                    : `${basePath}${this.configuration.id}/`;

                await api.patch(path, { config: config });
                this.$redirectToPage('TemplatePage', { id: this.configuration.template });
            }
        },
        setConfigFields() {
            
            this.fieldsConfig = this.configuration.config.fields;

            this.optionalSectionsConfig = this.configuration.config.optional_sections || {};

            this.repetitiveFieldsConfig = this.configuration.config.repetitive_data || {};

            this.linkedDBModels = this.configuration.config.linked_objects || {};

        },
        addLinkedDBObject() {
            this.linkedDBModels['object_' + (Object.keys(this.linkedDBModels).length+1)] = {
                name: null,
                app: null,
                model: null,
                multiple: false,
                ready: false
            };
        },
        appSelected(index, selectedValue) {
            this.linkedDBModels[index].app = selectedValue;
            this.linkedDBModels[index].ready = false;
        },
        modelSelected(index, selectedValue) {
            this.linkedDBModels[index].model = selectedValue;
            this.linkedDBModels[index].ready = this.isObjectReady(index);
        },
        objFieldSelected(index, selectedValue) {
            this.fieldsConfig[index].objLink = selectedValue;
        },
        optObjFieldSelected(index, selectedValue) {
            this.optionalSectionsConfig[index].objLink = selectedValue;
        },
        repObjSelected(section, selectedValue) {
            this.repetitiveFieldsConfig[section].linkedObject = selectedValue;
        },
        repFieldSelected(section, field, selectedValue) {
            this.repetitiveFieldsConfig[section]['fields'][field].linkedField = selectedValue;
        },
        isObjectReady(index) {
            // TODO: Also run this when the name changes and when multiple is toggled
            return this.linkedDBModels[index].app && this.linkedDBModels[index].model && this.linkedDBModels[index].name;
        },

        DBModelsOptions(app) {
            return Object.keys(this.DBModels[app]).map(model => ({
                value: model,
                display: model.startsWith('Codigo01_') ? model.replace('Codigo01_', '') : model
            }));
        },
        DBObjects(filter=null) {
            var models = Object.fromEntries(
                Object.entries(this.linkedDBModels).filter((model) => model[1].ready)
            );

            if (filter == 'multiple') {
                models = Object.fromEntries(
                    Object.entries(models).filter((model) => model[1].multiple)
                );
            } else if (filter == 'individual') {
                models = Object.fromEntries(
                    Object.entries(models).filter((model) => !model[1].multiple)
                );
            }

            return Object.keys(models).map(key => ({
                name: key,
                display: this.linkedDBModels[key].name,
                fields: this.DBModels[this.linkedDBModels[key].app][this.linkedDBModels[key].model].map(field => ({
                    name: field,
                    display: field,
                })),
                value: key
            }));
        },
        objectFields(objectKey) {
            const object = this.linkedDBModels[objectKey];
            return this.DBModels[object.app][object.model].map(field => ({
                value: field,
                display: field,
            }));
        }
    }
};
</script>

<style scoped>
.configuration-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin-bottom: 10px;
  gap: 15px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.form-fields, .pdf-viewer, .db-relations {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

.field-item, .db-record-fields {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  gap: 5px;
}

h3, h4 {
    margin: 0 0 10px 0;
}

ul {
    margin: 0
}

.repetitive-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.repDBLink {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
}

.field-options {
  margin-top: 5px;
}

.field-label, h4 {
    margin-bottom: 15px;
}

.text-field {
  padding: 8px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.generate-btn, .reset-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.generate-btn:hover {
  background-color: #218838;
}

.reset-btn {
  background-color: #dc3545;
}

.reset-btn:hover {
  background-color: #c82333;
}

.add-item-btn, .remove-item-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.add-item-btn:hover, .remove-item-btn:hover {
  background-color: #0056b3;
}

.error-message {
  margin-top: 10px;
  color: red;
}

.config-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
</style>
