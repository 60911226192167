<template>
    <AppBaseLayout>
        <div class="import-page">
            <h1>Importación de Pagos</h1>
            
            <div class="upload-section">
                <input type="file" multiple @change="handleFileUpload" />
                <button class="analyze-button" @click="analyzeFiles">Analizar Archivos</button>
            </div>

            <div v-if="isAnalyzing" class="progress">
                <p>Analizando archivos, por favor espera...</p>
                <div class="loader"></div>
            </div>

            <div v-if="analysisComplete" class="results-section">
                <h2>Resultados del Análisis</h2>
                <p v-if="errors.length === 0" class="success-message">No se encontraron errores. ¡Análisis exitoso!</p>
                <ul v-else class="error-list">
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
                <button class="download-button" @click="downloadFile">Descargar Archivo de Salida</button>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';

export default {
    name: 'CreditsImportPage',
    components: {
        AppBaseLayout,
    },
  data() {
    return {
      files: [],
      isAnalyzing: false,
      analysisComplete: false,
      errors: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      this.files = Array.from(event.target.files);
    },
    analyzeFiles() {
      if (this.files.length === 0) {
        alert("Por favor sube al menos un archivo.");
        return;
      }
      this.isAnalyzing = true;
      this.analysisComplete = false;
      this.errors = [];

      // Simula una llamada a la API con un retraso de 5 segundos
      setTimeout(() => {
        this.isAnalyzing = false;
        this.analysisComplete = true;

        // Resultados simulados
        this.errors = Math.random() > 0.5 ? ["Error de muestra 1", "Error de muestra 2"] : [];
      }, 5000);
    },
    downloadFile() {
      // Simula la descarga del archivo creando una URL de ejemplo
      const fileUrl = "https://example.com/dummy-output-file.csv";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "archivo_de_salida.csv";
      link.click();
    },
  },
};
</script>

<style scoped>
.import-page {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 1.8rem;
  color: #333;
}

.upload-section {
  margin-top: 20px;
}

input[type="file"] {
  margin-top: 15px;
}

.analyze-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0073e6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 115, 230, 0.2);
  transition: background-color 0.2s, box-shadow 0.2s;
}

.analyze-button:hover {
  background-color: #005bb5;
  box-shadow: 0 8px 15px rgba(0, 115, 230, 0.3);
}

.progress {
  margin-top: 20px;
}

.progress .loader {
  margin: 15px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0073e6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.results-section {
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.success-message {
  color: #2ecc71;
  font-size: 1.2rem;
}

.error-list {
  color: #e74c3c;
  font-size: 1rem;
  text-align: left;
  margin: 10px 0;
}

.download-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(46, 204, 113, 0.2);
  transition: background-color 0.2s, box-shadow 0.2s;
}

.download-button:hover {
  background-color: #27ae60;
  box-shadow: 0 8px 15px rgba(46, 204, 113, 0.3);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>