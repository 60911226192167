<template>
    <div class="custom-toggle-field" @click="toggleSwitch">
      <label class="toggle-title">{{ title }}</label>
      <div class="toggle-switch" :class="{ 'switch-on': modelValue }">
        <div class="toggle-knob"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomToggleField",
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "Toggle Label",
      },
    },
    methods: {
      toggleSwitch() {
        this.$emit("update:modelValue", !this.modelValue);
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-toggle-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  .toggle-title {
    font-size: 14px;
    color: #555;
    margin-bottom: 4px; /* Minimal spacing */
  }
  
  .toggle-switch {
    width: 60px;
    height: 28px;
    background-color: #d1d1d1;
    border-radius: 14px;
    position: relative;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    padding: 0 4px;
    box-sizing: border-box;
  }
  
  .toggle-switch.switch-on {
    background-color: #007bff;
  }
  
  .toggle-knob {
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 4px;
    transition: left 0.3s ease;
  }
  
  .toggle-switch.switch-on .toggle-knob {
    left: 32px;
  }
  </style>
  