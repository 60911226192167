<template>
  <BaseLayout>
    <!-- Hero Section -->
    <section class="hero animated">
      <div class="row animated">
        <h1>Bienvenido a</h1>
        <img :src="logoUrl" alt="Codigo01 Logo" class="logo animated"/>
      </div>
      <p class="animated"></p>
      <button @click="exploreModules" class="animated">Explorar Sistemas</button>
    </section>

    <!-- Modules Section -->
    <section class="modules">
      <ModuleCard
        v-for="module in appsMeta"
        :key="module.id"
        :module="module"
        class="module-card animated"
      />
    </section>
  </BaseLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BaseLayout from '../../components/layouts/BaseLayout.vue';
import ModuleCard from './ModuleCard.vue';

const appsData = {
  'financieras': {
    name: 'Financieras',
    description: 'Administra las finanzas de tu empresa fácilmente.',
    icon: 'bi bi-wallet2',
    dashboardPage: 'FinancierasDashboard'
  },
  'funerarias': {
    name: 'Funerarias',
    description: 'Gestiona los servicios funerarios de tu empresa.',
    icon: 'bi bi-clipboard-data',
    dashboardPage: 'FunerariasDashboard'
  },
  'evaluacion-digital': {
    name: 'Evaluación Digital',
    description: 'Realiza evaluaciones digitales de tus alumnos.',
    icon: 'bi bi-file-earmark-text',
    dashboardPage: 'EvaluacionDigitalDashboard'
  }
} 

export default {
    name: 'HomePage',
    components: {
        BaseLayout,
        ModuleCard
    },
    computed: {
      ...mapState(['selectedApp']),
      ...mapGetters(['isAuthenticated']),
      currentPageName() {
        return this.$route.name;
      },
      appsMeta() {
        return Object.keys(appsData).map(key => ({
          id: key,
          ...appsData[key]
        }))
      }
    },
    data() {
        return {
        logoUrl: require('@/assets/logo.png'),
        };
    },
    methods: {
        exploreModules() {
        // Logic for exploring modules, e.g., scroll to the module section
        }
    },
    created() {
      if (this.isAuthenticated) {
        const appMeta = appsData[this.selectedApp];
        if (appMeta) {
          this.$redirectToPage(appMeta.dashboardPage || 'HomePage');
        } else {
          this.$redirectToPage('LoginPage');
        }
      }
    },
};
</script>
  
  <style scoped>
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated {
    animation: fadeInUp 0.7s ease-out;
  }
  
  /* Home Page Styling */
  .home-page {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f2f2f2; /* Light gray background */
    color: #1E3A8A; /* Blue text color for contrast */
  }
  
  .logo {
    height: 60px;
    border-radius: 30px;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .hero h1 {
    font-size: 3rem;
    color: #007BFF; /* Clearer blue for the header */
  }
  
  .hero button {
    background-color: #007BFF; /* Clear blue for the button */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .hero button:hover {
    background-color: #0056b3;
  }
  
  .modules {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 0;
    gap: 20px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  </style>
  