<template>
  <AppBaseLayout>
      <div class="import-page">
          <h1>Importación de Créditos</h1>
          
          <div class="upload-section">
              <input type="file" multiple @change="handleFileUpload" />
              <button class="analyze-button" @click="analyzeFiles">Analizar Archivos</button>
          </div>

          <div v-if="isAnalyzing" class="progress">
              <p>Analizando archivos, por favor espera...</p>
              <div class="loader"></div>
          </div>

          <div v-if="analysisComplete" class="results-section">
              <h2>Resultados del Análisis</h2>
              <p v-if="errorMessage == null" class="success-message">{{ successMessage }}</p>
              <p v-else class="error-message">{{ errorMessage }}</p>

                <ul v-if="comments.length > 0" class="comment-list">
                  <li v-for="(comment, index) in comments" :key="index">{{ comment }}</li>
                </ul>

                <ul v-if="errors.length > 0" class="error-list">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>

                <div class="button-row">
                    <button class="download-button" @click="downloadCreditsFile">Descargar Archivo de Créditos</button>
                    <button v-if="hasClientsFile" class="download-clients-button" @click="downloadClientsFile">Descargar Archivo de Clientes</button>
                    <button v-if="hasErrorsFile" class="download-errors-button" @click="downloadErrorsFile">Descargar Archivo de Errores</button>
                </div>
          </div>
      </div>
  </AppBaseLayout>
</template>

<script>
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';
import api from '@/services/api';

export default {
  name: 'CreditsImportPage',
  components: {
      AppBaseLayout,
  },
  data() {
      return {
          files: [],
          isAnalyzing: false,
          analysisComplete: false,
          successMessage: null,
          errorMessage: null,
          hasClientsFile: false,
          hasErrorsFile: false,
          errors: [], // Array to store the list of errors
          comments: [],
      };
  },
  methods: {
      handleFileUpload(event) {
          this.files = Array.from(event.target.files);
      },
      async analyzeFiles() {
          if (this.files.length === 0) {
              alert("Por favor sube al menos un archivo.");
              return;
          }
          this.isAnalyzing = true;
          this.analysisComplete = false;
          this.hasErrorsFile = false;
          this.hasClientsFile = false;
          this.successMessage = null;
          this.errorMessage = null;
          this.errors = []; // Clear previous errors
          this.comments = [];

          // Create a FormData object and append files
          const formData = new FormData();
          this.files.forEach((file, index) => {
              formData.append(`files[${index}]`, file);
          });

          try {
              // Send the files to the API endpoint
              const response = await api.post('/custom/fovi/data-import/credits/', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  },
              });

              this.isAnalyzing = false;
              this.analysisComplete = true;

              // Process API response
              this.creditsDownloadUrl = response.data.credits_file.content;
              this.creditsDownloadName = response.data.credits_file.name;
              this.creditsDownloadContentType = response.data.credits_file.content_type;

              if (response.data.clients_file) {
                  this.clientsDownloadUrl = response.data.clients_file.content;
                  this.clientsDownloadName = response.data.clients_file.name;
                  this.clientsDownloadContentType = response.data.clients_file.content_type;
                  this.hasClientsFile = true;
              } else {
                  this.successMessage = 'No se encontraron errores. ¡Análisis exitoso!';
              }

              if (response.data.errors_file) {
                  this.errorsDownloadUrl = response.data.errors_file.content;
                  this.errorsDownloadName = response.data.errors_file.name;
                  this.errorsDownloadContentType = response.data.errors_file.content_type;
                  this.successMessage = 'Se generó el documento pero se encontraron errores. Por favor revisa el archivo de errores.';
                  this.hasErrorsFile = true;
              } else {
                  this.successMessage = 'No se encontraron errores. ¡Análisis exitoso!';
              }

                if (response.data.comments && Array.isArray(response.data.comments)) {
                    this.comments = response.data.comments;
                }

              // If there are errors in the response, populate the errors array
              if (response.data.errors && Array.isArray(response.data.errors)) {
                  this.errors = response.data.errors;
              }
          } catch (error) {
              console.error('Error analyzing files:', error);
              this.isAnalyzing = false;
              this.analysisComplete = true;
              this.errorMessage = 'Error al analizar los archivos. Por favor intenta nuevamente.';
          }
      },
      downloadCreditsFile() {
          if (!this.creditsDownloadUrl || !this.creditsDownloadName || !this.creditsDownloadContentType) {
              alert("No hay archivo disponible para descargar.");
              return;
          }

          const byteCharacters = atob(this.creditsDownloadUrl);
          const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: this.creditsDownloadContentType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.creditsDownloadName;
          link.click();
          URL.revokeObjectURL(link.href);
      },
      downloadClientsFile() {
          if (!this.clientsDownloadUrl || !this.clientsDownloadName || !this.clientsDownloadContentType) {
              alert("No hay archivo disponible para descargar.");
              return;
          }

          const byteCharacters = atob(this.clientsDownloadUrl);
          const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: this.clientsDownloadContentType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.clientsDownloadName;
          link.click();
          URL.revokeObjectURL(link.href);
      },
      downloadErrorsFile() {
          if (!this.errorsDownloadUrl || !this.errorsDownloadName || !this.errorsDownloadContentType) {
              alert("No hay archivo disponible para descargar.");
              return;
          }

          const byteCharacters = atob(this.errorsDownloadUrl);
          const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: this.errorsDownloadContentType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.errorsDownloadName;
          link.click();
          URL.revokeObjectURL(link.href);
      },
  },
};
</script>

<style scoped>
.import-page {
max-width: 600px;
margin: auto;
padding: 20px;
text-align: center;
}

h1 {
font-size: 1.8rem;
color: #333;
}

.upload-section {
margin-top: 20px;
}

input[type="file"] {
margin-top: 15px;
}

.analyze-button {
margin-top: 20px;
padding: 10px 20px;
font-size: 1rem;
background-color: #0073e6;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
box-shadow: 0 4px 10px rgba(0, 115, 230, 0.2);
transition: background-color 0.2s, box-shadow 0.2s;
}

.analyze-button:hover {
background-color: #005bb5;
box-shadow: 0 8px 15px rgba(0, 115, 230, 0.3);
}

.progress {
margin-top: 20px;
}

.progress .loader {
margin: 15px auto;
border: 4px solid #f3f3f3;
border-top: 4px solid #0073e6;
border-radius: 50%;
width: 40px;
height: 40px;
animation: spin 1s linear infinite;
}

.results-section {
margin-top: 30px;
padding: 20px;
border-radius: 8px;
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
background-color: #f9f9f9;
}

.success-message {
color: #2ecc71;
font-size: 1.2rem;
}

.error-message {
color: #e74c3c;
font-size: 1.2rem;
}

.comment-list {
color: #333;
font-size: 1rem;
text-align: left;
margin: 10px 0;
}

.error-list {
color: #e74c3c;
font-size: 1rem;
text-align: left;
margin: 10px 0;
}

.button-row {
display: flex;
gap: 10px;
justify-content: center;
margin-top: 15px;
}

.download-button, .download-errors-button, .download-clients-button {
padding: 10px 20px;
font-size: 1rem;
background-color: #2ecc71;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
box-shadow: 0 4px 10px rgba(46, 204, 113, 0.2);
transition: background-color 0.2s, box-shadow 0.2s;
}

.download-button:hover {
background-color: #27ae60;
box-shadow: 0 8px 15px rgba(46, 204, 113, 0.3);
}

.download-clients-button {
background-color: #3498db;
box-shadow: 0 4px 10px rgba(52, 152, 219, 0.2);
}

.download-clients-button:hover {
background-color: #2980b9;
box-shadow: 0 8px 15px rgba(52, 152, 219, 0.3);
}

.download-errors-button {
background-color: #e74c3c;
box-shadow: 0 4px 10px rgba(231, 76, 60, 0.2);
}

.download-errors-button:hover {
background-color: #c0392b;
box-shadow: 0 8px 15px rgba(231, 76, 60, 0.3);
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
</style>