<template>
  <div class="simple-dropdown-field">
    <!-- Label -->
    <label class="dropdown-label">{{ label }}</label>

    <!-- Dropdown Selector -->
    <select v-model="selectedValue" @change="emitSelection" class="custom-selector">
      <option value="" disabled>Selecciona una Opción</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.display }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SimpleDropdownField",
  props: {
    label: {
      type: String,
      default: "Selecciona una Opción",
    },
    options: {
      type: Array,
      required: true,
      default: () => [
        { value: "option1", display: "Option 1" },
        { value: "option2", display: "Option 2" },
      ],
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedValue: this.initialValue,
    };
  },
  methods: {
    emitSelection() {
      this.$emit("selectionChanged", this.selectedValue);
    },
  },
  watch: {
    initialValue(newInitialValue) {
      if (this.options.find(option => option.value === newInitialValue)) {
        this.selectedValue = newInitialValue;
      }
    },
  },
  mounted() {
    // Set selectedValue to initialValue if it exists in options
    if (!this.options.find(option => option.value === this.selectedValue)) {
      this.selectedValue = ""; // Clear if the initial value is invalid
    }
  },
};
</script>

<style scoped>
.simple-dropdown-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown-label {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
}

.custom-selector {
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
</style>
