<template>
    <AppBaseLayout>
    </AppBaseLayout>
</template>

<script>
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';

export default {
    components: {
        AppBaseLayout,
    },
};
</script>