import { createStore } from 'vuex';
import api from '@/services/api.js';

export default createStore({
  state: {
    user: null,  // This will hold the user data
    company: null,
    branch: null,
    branches: [],
    userApps: [],
    userGroups: [],
    token: localStorage.getItem('authToken'),  // Retrieve token from localStorage
    selectedApp: localStorage.getItem('selectedApp'),
    adminMode: localStorage.getItem('adminMode') === 'true'
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('authToken');
    },
    setToken(state, token) {
      state.token = token;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setBranch(state, branch) {
      state.branch = branch;
    },
    setBranches(state, branches) {
      state.branches = branches;
    },
    setUserApps(state, apps) {
      state.userApps = apps;
    },
    setUserGroups(state, groups) {
      state.userGroups = groups;
    },
    setSelectedApp(state, app) {
      state.selectedApp = app;
    },
    setAdminMode(state, mode) {
      state.adminMode = mode;
      localStorage.setItem('adminMode', mode);
    },
    toggleAdminMode(state) {
      state.adminMode = !state.adminMode;
      localStorage.setItem('adminMode', state.adminMode);
    }
  },
  actions: {
    async checkAuthStatus({ commit, dispatch }) {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await api.get('user/');  // Adjust this API call as needed
          commit('setUser', response.data);
          await dispatch('getCompany');
          await dispatch('getUserApps');
          await dispatch('getUserGroups');
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          commit('clearUser');
        }
      } else {
        console.log('No token found');
        commit('clearUser');
      }
    },
    logout({ commit }) {
      localStorage.removeItem('authToken');
      commit('clearUser');
    },
    async getCompany({ commit, dispatch }) {
      try {
        const response = await api.get('company/');
        commit('setCompany', response.data);  // Commit company data to the state
        dispatch('getBranches');
      } catch (error) {
        console.error('Failed to fetch company data:', error);
      }
    },
    async getBranches({ commit }) {
      try {
        const response = await api.get('company/branches/');
        commit('setBranches', response.data);  // Commit branch data to the state
        if (response.data.length > 0) {
          commit('setBranch', response.data[0]);  // Set the first branch as the default
        }
      } catch (error) {
        console.error('Failed to fetch branch data:', error);
      }
    },
    async getUserApps({ commit, state }) {
      try {
        const response = await api.get('user-apps/');
        commit('setUserApps', response.data);  // Commit user apps to the state
        if (!state.selectedApp || !response.data.includes(state.selectedApp)) {
          if (response.data.length > 0) {
            localStorage.setItem('selectedApp', response.data[0]);
            commit('setSelectedApp', response.data[0]);  // Set the first app as the selected app if none is selected or if the selected app is not in the response data
          } else {
            localStorage.removeItem('selectedApp');
            commit('setSelectedApp', null);  // Set the selected app to null if there are no apps
          }
        }
      } catch (error) {
        console.error('Failed to fetch user apps:', error);
      }
    },
    async getUserGroups({ commit }) {
      try {
        const response = await api.get('user-groups/');
        commit('setUserGroups', response.data);  // Commit user groups to the state
      } catch (error) {
        console.error('Failed to fetch user groups:', error);
      }
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.user,  // Return true if user is authenticated
    isAdmin: (state) => ((state.userGroups.includes('Admin') || state.userGroups.includes('Master')) && state.adminMode),  // Return true if user is an admin
    canAdmin: (state) => (state.userGroups.includes('Admin') || state.userGroups.includes('Master')),  // Return true if user can admin
    getUser: (state) => state.user,
    getBranch: (state) => state.branch,
  }
});