<template>
  <div class="linked-field-selector">
    <label class="section-title">{{ sectionTitle }}</label>
    <div class="selectors-row">
      <!-- Object Selection Dropdown -->
      <div class="dropdown">
        <label for="object-select">Objeto</label>
        <select id="object-select" v-model="selectedObject" @change="updateFields">
          <option value="" disabled>Selecciona un Objeto</option>
          <option v-for="object in objects" :key="object.name" :value="object.name">
            {{ object.display }}
          </option>
        </select>
      </div>

      <!-- Field Selection Dropdown -->
      <div class="dropdown" v-if="selectedObjectFields.length">
        <label for="field-select">Campo</label>
        <select id="field-select" v-model="selectedField">
          <option value="" disabled>Selecciona un Campo</option>
          <option v-for="field in selectedObjectFields" :key="field.name" :value="field.name">
            {{ field.display }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkedFieldSelector",
  props: {
    sectionTitle: {
      type: String,
      default: "Conexión con Objeto en DB",
    },
    objects: {
      type: Array,
      required: true,
      default: () => [
        {
          name: "Object1",
          display: "First Object",
          fields: [
            { name: "field1", display: "Field 1" },
            { name: "field2", display: "Field 2" },
          ],
        },
        {
          name: "Object2",
          display: "Second Object",
          fields: [
            { name: "fieldA", display: "Field A" },
            { name: "fieldB", display: "Field B" },
          ],
        },
      ],
    },
    initialObject: {
      type: String,
      default: "",
    },
    initialField: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedObject: this.initialObject,
      selectedField: this.initialField,
    };
  },
  computed: {
    selectedObjectFields() {
      const object = this.objects.find((object) => object.name === this.selectedObject);
      return object ? object.fields : [];
    },
  },
  methods: {
    updateFields() {
      this.selectedField = ""; // Reset selected field when object changes
      this.$emit("objectSelected", this.selectedObject);
    },
  },
  watch: {
    selectedObject(newObject) {
      // Emit initial object change if set initially
      this.$emit("objectSelected", newObject);
      if (this.selectedField && !this.selectedObjectFields.find(field => field.name === this.selectedField)) {
        this.selectedField = ""; // Reset field if it doesn’t match new object
      }
    },
    selectedField(newField) {
      this.$emit("fieldSelected", { object: this.selectedObject, field: newField });
    },
  },
  mounted() {
    // Ensure the initial field value is valid for the selected object
    if (this.selectedObject && !this.selectedObjectFields.find(field => field.name === this.selectedField)) {
      this.selectedField = ""; // Clear if invalid
    }
  }
};
</script>

<style scoped>
.linked-field-selector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.selectors-row {
  display: flex;
  gap: 12px;
  width: 100%;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown label {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
}

.dropdown select {
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
</style>
