<template>
    <AppBaseLayout>
      <div class="onboarding-page">
        <!-- Onboarding Steps Navigation -->
        <div class="onboarding-progress">
          <div v-for="(step, index) in steps" :key="index" 
               class="step-container" 
               :class="{ active: currentStep === index, completed: index < currentStep }"
               @click="goToStep(index)">
            <div class="circle">
              <i :class="step.icon"></i>
            </div>
            <p>{{ step.title }}</p>
          </div>
        </div>
  
        <!-- Onboarding Step Content -->
        <div class="onboarding-content">
          <h2>{{ steps[currentStep].title }}</h2>
          <p>{{ steps[currentStep].description }}</p>
  
          <!-- Dynamic Form Fields for Each Step -->
          <div v-if="currentStep === 0" class="input-group">
            <label>Monto Solicitado:</label>
            <input type="number" v-model="form.montoSolicitado" placeholder="Ingrese el monto">
            
            <label>Plazo (meses):</label>
            <input type="number" v-model="form.plazo" placeholder="Ingrese el plazo">
          </div>
  
          <div v-if="currentStep === 1" class="input-group">
            <label>Nombre Completo:</label>
            <input type="text" v-model="form.nombre" placeholder="Ingrese su nombre completo">
            
            <label>CURP:</label>
            <input type="text" v-model="form.curp" placeholder="Ingrese su CURP">
            
            <label>RFC:</label>
            <input type="text" v-model="form.rfc" placeholder="Ingrese su RFC">
          </div>
  
          <div v-if="currentStep === 2" class="input-group">
            <p>Estamos consultando su historial crediticio...</p>
          </div>
  
          <div v-if="currentStep === 3" class="input-group">
            <p>Validando información contra listas PLD...</p>
          </div>
  
          <div v-if="currentStep === 4" class="input-group">
            <label>Sube una selfie:</label>
            <input type="file" @change="handleSelfieUpload">
          </div>
  
          <div v-if="currentStep === 5" class="input-group">
            <p>Listo para firmar digitalmente. Por favor, verifique el documento y firme electrónicamente.</p>
            <button class="firma-btn">Firmar Documento</button>
          </div>
  
          <!-- Navigation Buttons -->
          <div class="navigation">
            <button @click="prevStep" :disabled="currentStep === 0" class="nav-btn back">Atrás</button>
            <button @click="nextStep" :disabled="currentStep === steps.length - 1" class="nav-btn next">Siguiente</button>
          </div>
        </div>
      </div>
    </AppBaseLayout>
  </template>
  
  <script>
  import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';
  
  export default {
    name: 'OnboardingPage',
    components: { AppBaseLayout },
    data() {
      return {
        currentStep: 0,
        steps: [
          { title: "Cotizador Inicial", description: "Ingrese el monto y el plazo deseado.", icon: "bi bi-calculator" },
          { title: "Captura de Datos", description: "Proporcione su información personal.", icon: "bi bi-person" },
          { title: "Consulta Crediticia", description: "Verificamos su historial crediticio.", icon: "bi bi-credit-card" },
          { title: "Validación PLD", description: "Verificación en listas PLD.", icon: "bi bi-shield-check" },
          { title: "Prueba de Vida", description: "Verificación biométrica.", icon: "bi bi-camera" },
          { title: "Firma Digital", description: "Firma electrónica del contrato.", icon: "bi bi-pencil-square" }
        ],
        form: {
          montoSolicitado: "",
          plazo: "",
          nombre: "",
          curp: "",
          rfc: "",
        }
      };
    },
    methods: {
      nextStep() {
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
        }
      },
      prevStep() {
        if (this.currentStep > 0) {
          this.currentStep--;
        }
      },
      goToStep(index) {
        this.currentStep = index;
      },
      handleSelfieUpload(event) {
        console.log("Selfie uploaded:", event.target.files[0]);
      }
    }
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .onboarding-page {
    padding: 40px;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Onboarding Progress Steps */
  .onboarding-progress {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;
  }
  
  .step-container {
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  
  .step-container:hover {
    transform: scale(1.05);
  }
  
  .step-container p {
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    padding: 5px;
    background: #f5f5f5;
    border-radius: 4px;
    text-align: center;
    min-width: 120px;
  }
  
  /* Step Circles */
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    border: 2px solid #ccc;
    background: #007bff;
    color: white;
    transition: background 0.3s ease-in-out;
  }
  
  .step-container.active .circle {
    background: #00b894;
  }
  
  .step-container.completed .circle {
    background: #28a745;
  }
  
  /* Step Content */
  .onboarding-content {
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
  }
  
  .onboarding-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .onboarding-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Form Fields */
  .input-group {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input-group input,
  .input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .input-group input:focus,
  .input-group select:focus {
    border-color: #007bff;
  }
  
  /* Navigation Buttons */
  .navigation {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-btn {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: white;
  }
  
  .nav-btn.back {
    background: #ff6b6b;
  }
  
  .nav-btn.back:hover {
    background: #ff3838;
  }
  
  .nav-btn.next {
    background: #007bff;
  }
  
  .nav-btn.next:hover {
    background: #0056b3;
  }
  
  button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .firma-btn {
    background: #28a745;
    color: white;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .firma-btn:hover {
    background: #218838;
  }
  </style>  