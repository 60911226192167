import OnBoardingDashboard from "./views/OnBoardingDashboard.vue";

const OnBoardingRoutes = [
    {
        path: '/onboarding/',  // The base path for the sub-app
        children: [
            {
                path: '',
                name: 'OnBoardingDashboard',
                component: OnBoardingDashboard,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default OnBoardingRoutes;
