import DataImporterDashboard from './views/DataImporterDashboard.vue';
import ClientsImportPage from './views/ClientsImportPage.vue';
import CreditsImportPage from './views/CreditsImportPage.vue';
import PaymentsImportPage from './views/PaymentsImportPage.vue';

const DataImporterRoutes = [
    {
        path: '/data-import/',  // The base path for the sub-app
        children: [
            {
                path: '',
                name: 'DataImporterDashboard',
                component: DataImporterDashboard,
                meta: { requiresAuth: true }
            },
            {
                path: 'clients/',
                name: 'ClientsImportPage',
                component: ClientsImportPage,
                meta: { requiresAuth: true }
            },
            {
                path: 'credits/',
                name: 'CreditsImportPage',
                component: CreditsImportPage,
                meta: { requiresAuth: true }
            },
            {
                path: 'payments/',
                name: 'PaymentsImportPage',
                component: PaymentsImportPage,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default DataImporterRoutes;
