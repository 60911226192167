<template>
    <AppBaseLayout>
        <div class="document-generation-page" v-if="template">
            <h2>Generación de Documento con Plantilla {{ template.name }}</h2>
            <div class="sections">
                <div class="pdf-viewer">
                    <h3>Ejemplo de Documento Generado</h3>
                    <iframe :src="template.pdf_file" width="100%" height="500px" frameborder="0"></iframe>
                </div>

                <div class="config-selection">
                    <h3>Selecciona una Configuración</h3>
                    <div class="configurations">
                        <div v-for="config in customConfigurations" :key="config.id">
                            <button 
                                :class="{ 'selected-config': selConfig === config.config }" 
                                @click="selectConfig(config.config)">
                                {{ config.id }}
                            </button>
                        </div>
                        <div v-for="config in configurations" :key="config.id">
                            <button 
                                :class="{ 'selected-config': selConfig === config.config }" 
                                @click="selectConfig(config.config)">
                                {{ config.id }}
                            </button>
                        </div>
                    </div>
                    <Checkbox v-model="useDBLinks" title="Usar Conexión a DB" />
                </div>

                <!-- Fields Input Section -->
                <div class="input-fields" v-if="selConfig">

                    <div class="form-fields" v-if="!useDBLinks">
                        <!-- Main Fields Input -->
                        <div>
                            <h3>Asigna Valores a los Campos Principales</h3>
                            <div class="fields-list">
                                <div v-for="(value, key) in fieldsConfig" :key="key" class="field-item">
                                    <label class="field-label">{{ value.label }}: <span class="field-value">{{ value.type }}</span></label>
                                    <TextField v-model="fieldsValues[key]['value']" title="Valor"/>
                                </div>
                            </div>
                        </div>

                        <!-- Repetitive Fields Input -->
                        <div v-if="Object.keys(repetitiveFieldsConfig).length > 0">
                            <h3>Asigna Valores a los Campos Repetitivos</h3>
                            <div class="repetitive-fields">
                                <div v-for="(sect_data, section) in repetitiveFieldsConfig" :key="section" class="repetitive-field-item">
                                    <h4>Lista de {{ repetitiveFieldsConfig[section].label }}</h4>
                                    <div v-for="(item, index) in repetitiveValues[section]" :key="index" class="repetitive-item">
                                        <div v-for="(value, key) in sect_data.fields" :key="key" class="field-item">
                                            <label class="field-label">{{ value.label }}: <span class="field-value">{{ value.type }}</span></label>
                                            <TextField v-model="repetitiveValues[section][index][key]" title="Valor"/>
                                        </div>
                                        <button @click="removeRepetitiveItem(section, index)" class="remove-item-btn">Eliminar elemento de {{ repetitiveFieldsConfig[section].label }}</button>
                                    </div>
                                    <button @click="addRepetitiveItem(section)" class="add-item-btn">Agregar elemento de {{ repetitiveFieldsConfig[section].label }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="db-links" v-if="useDBLinks">
                        <div>
                            <h3>Asigna la información a utilizar</h3>
                            <div v-for="(value, key) in objectsValues" :key="key" class="field-item">
                                <TextField 
                                    v-model="objectsValues[key]" 
                                    :title="objectsConfig[key].name" 
                                    style="margin-top: 15px; margin-bottom: 0" 
                                    v-if="!objectsConfig[key].multiple"
                                />
                                <div v-else>
                                    <h4>{{ objectsConfig[key].name }}</h4>
                                    <div v-for="(item, index) in objectsValues[key]" :key="index" class="field-item">
                                        <TextField v-model="objectsValues[key][index]" :title="objectsConfig[key].name" style="margin-top: 15px; margin-bottom: 0"/>
                                        <button @click="removeObjectValue(key, index)" class="remove-item-btn">Eliminar</button>
                                    </div>
                                    <button @click="addObjectValue(key)" class="add-item-btn">Agregar a {{ objectsConfig[key].name }}</button>
                                </div>
                                <!--<LinkedFieldDropdown v-model="objectsValues[key]" :label="objectsConfig[key].name" :options="objectsOptions[key]" />-->
                            </div>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                    </div>

                    <!-- Submit Button -->
                    <button @click="generateDocument" class="generate-btn">Generar Documento</button>
                </div>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';
import TextField from '@/components/TextField.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
    components: {
        AppBaseLayout,
        TextField,
        Checkbox
    },
    data() {
        return {
            template: null,
            selConfig: null,
            configurations: [],
            customConfigurations: [],

            fieldsValues: {},
            repetitiveValues: {},
            objectsValues: {},

            fieldsConfig: {},
            repetitiveFieldsConfig: {},
            objectsConfig: {},

            objectsOptions: {},

            useDBLinks: true,

            errorMessage: ''
        };
    },
    computed: {
        templateId() {
            return this.$route.params.id;
        },
    },
    async created() {
        const templateResponse = await api.get(`doc-generator/templates/${this.templateId}/`);
        this.template = templateResponse.data;

        const configsResponse = await api.get(`doc-generator/templates/configurations/`, { params: { template: this.template.id } });

        this.configurations = configsResponse.data;

        const customConfigsResponse = await api.get(`doc-generator/templates/configurations/custom/`, { params: { template: this.template.id } });

        this.customConfigurations = customConfigsResponse.data;

        if (this.customConfigurations.length > 0) {
            this.selectConfig(this.customConfigurations[0].config);
        } else if (this.configurations.length > 0) {
            this.selectConfig(this.configurations[0].config);
        }

    },
    methods: {
        selectConfig(config) {
            this.selConfig = config;
            this.setFields();
        },
        setFields() {
            this.fieldsConfig = this.selConfig.fields;

            this.repetitiveFieldsConfig = this.selConfig.repetitive_data || {};

            this.fieldsValues = Object.entries(this.template.variables)
                .filter(([key]) => key !== 'repetitive_data' && key !== 'optional_sections')
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
            
            this.repetitiveValues = Object.fromEntries(Object.keys(this.repetitiveFieldsConfig).map(section => [section, [{}]]));

            this.objectsConfig = this.selConfig.linked_objects || {};

            console.log('Objects Config:', this.objectsConfig);

            this.objectsValues = Object.keys(this.objectsConfig).reduce((acc, key) => {
                acc[key] = this.objectsConfig[key].multiple ? [''] : '';
                return acc;
            }, {});

            console.log('Objects Values:', this.objectsValues);
        },
        addRepetitiveItem(section) {
            const newItem = {};
            for (const key in this.repetitiveFieldsConfig[section].fields) {
                newItem[key] = '';
            }
            this.repetitiveValues[section].push(newItem);
        },
        removeRepetitiveItem(section, index) {
            this.repetitiveValues[section].splice(index, 1);
        },
        addObjectValue(key) {
            this.objectsValues[key].push('');
        },
        removeObjectValue(key, index) {
            this.objectsValues[key].splice(index, 1);
        },
        async generateDocument() {
            const documentData = {
                //fields: Object.fromEntries(Object.entries(this.fieldsValues).map(([key, value]) => [key, value.value])),
                ...Object.fromEntries(Object.entries(this.fieldsValues).map(([key, value]) => [key, value.value])),
                repetitive_data: this.repetitiveValues
            };

            console.log(this.objectsValues);

            if (this.useDBLinks) {
                documentData.linked_objects = this.objectsValues;
            }

            try {
                const documentResponse = await api.post(`doc-generator/documents/`, { template: this.template.id, data: documentData, link_to_DB: this.useDBLinks });
                this.errorMessage = '';
                window.open(documentResponse.data.file, '_blank');
            } catch (error) {
                this.errorMessage = error.response.data.detail || 'Error al generar el documento. Por favor, revisa que los campos sean validos.';
            }
        }
    }
};
</script>

<style scoped>
.document-generation-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin-bottom: 10px;
}

.input-fields {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

.pdf-viewer, .config-selection {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

h3, h4 {
    margin: 0 0 10px 0;
}

ul {
    margin: 0
}

.field-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.field-label {
    margin-bottom: 15px;
}

.text-field {
  padding: 8px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.repetitive-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.repetitive-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #eef5fb;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.configurations button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin: 5px 0;
    font-size: 1rem;
    width: 100%;
}

.configurations button:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.selected-config {
    background-color: #28a745;
    color: white;
}

.selected-config:hover {
    background-color: #218838;
}

.add-item-btn, .remove-item-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.add-item-btn:hover, .remove-item-btn:hover {
  background-color: #0056b3;
}

.generate-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.generate-btn:hover {
  background-color: #218838;
}

.error-message {
    margin-top: 10px;
    color: red;
}
</style>
