import DocGeneratorRoutes from "./DocGenerator/router";
import DataImporterRoutes from "./DataImporter/router";
import OnBoardingRoutes from "./OnBoarding/router";

const ModulesRoutes = [
    ...DocGeneratorRoutes,
    ...DataImporterRoutes,
    ...OnBoardingRoutes
];

export default ModulesRoutes;
