<template>
    <AppBaseLayout>
        <div class="import-container">
            <h1>Importación de Datos</h1>
            <p>Selecciona una opción para proceder con la importación de datos:</p>
            <div class="buttons-container">
              <router-link :to="{ name: 'ClientsImportPage' }">
                <button class="import-button">Importación de Clientes</button>
              </router-link>
            <router-link :to="{ name: 'CreditsImportPage' }">
                <button class="import-button">Importación de Créditos</button>
            </router-link>
            <router-link :to="{ name: 'PaymentsImportPage' }">
                <button class="import-button">Importación de Pagos</button>
            </router-link>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBaseLayout from '@/components/layouts/AppBaseLayout.vue';

export default {
name: 'DataImporterDashboard',
components: {
    AppBaseLayout,
},
data() {
    return {
    searchQuery: '', // Holds the search input value
    templates: [],
    };
},
computed: {
    ...mapGetters(['isAdmin']),
    // Computed property to filter the templates based on the search query
},
};
</script>

<style scoped>
.import-container {
  text-align: center;
  padding: 40px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 0;
}

p {
  font-size: 1.2rem;
  color: #555;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.import-button {
  padding: 15px 30px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #0073e6;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 115, 230, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.import-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 115, 230, 0.3);
}
</style>