// src/services/api.js

import axios from 'axios';
import store from '@/store';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://api.codigo01.mx/api/',
  headers: {
    'Content-Type': 'application/json', // Ensures the content type is set correctly
  }
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Get token from local storage
    const isAdmin = store.getters.isAdmin; // Get isAdmin from Vuex store
    const branch = store.state.branch
    if (token) {
      config.headers['Authorization'] = `Token ${token}`; // Add the token to Authorization header
    }
    if (isAdmin) {
      config.headers['Is-Admin'] = 'true'; // Add Admin header if user is an admin
    }
    if (branch) {
      config.headers['branch'] = branch.id; // Add Branch header if a branch is selected
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
